import React, {useEffect, useState} from "react"
import {Link, useNavigate} from "react-router-dom"
import {api} from "@indebted/api"
import {useNotification} from "@indebted/components/Notification"
import {IFrame} from "@indebted/components/IFrame"
import styled from "styled-components"
import {useDebounce, useComputedState} from "@indebted/hooks"
import {
	Autocomplete,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	TextField,
	InputLabel,
	Select,
	RadioGroup,
	Radio,
} from "@mui/material"
import {theme} from "@indebted/theme"
import {LoadingPage} from "@indebted/components/Loading"
import {ErrorPage} from "@indebted/components/Error"

function Form(props) {
	const [component, setComponent] = useState(<LoadingPage />)
	useEffect(() => {
		api.smsTemplate
			.new()
			.then((res) => {
				setComponent(
					<FormContent
						{...props}
						tags={res.Tags}
						validationPeriodOptions={res.ValidationPeriodOptions}
						layouts={res.Layouts}
					/>,
				)
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} />)
			})
	}, [props])
	return component
}

// eslint-disable-next-line complexity
function FormContent({title, initialState, disabled, tags, validationPeriodOptions, layouts}) {
	const [form, setForm] = useState(initialState)
	const [archived, setArchived] = useState(initialState.Archived)
	const {notification} = useNotification()
	const navigate = useNavigate()
	const currentLayout = layouts.find((layout) => layout.LayoutID === form.LayoutID)

	const handleToggle = () => {
		const _api = api.smsTemplate
		const toggleFn = archived ? _api.restore : _api.archive
		toggleFn(initialState.ID)
			.then(() => {
				notification.success(archived ? "Template restored" : "Template archived")
				setArchived(!archived)
			})
			.catch((error) => notification.error(error.Message))
	}

	const handleLayoutOnChange = ({target: {value}}) => {
		const selectedLayout = layouts.find((layout) => layout.LayoutID === value)

		if (selectedLayout.Locales.indexOf(form.Locale) === -1) {
			setForm({
				...form,
				Locale: selectedLayout.Locales[0],
				LayoutID: value,
			})
		} else {
			setForm({
				...form,
				LayoutID: value,
			})
		}
	}

	const handleLocaleOnChange = ({target: {value}}) => {
		setForm({...form, ["Locale"]: value})
	}

	const previewOptions = {
		OriginatingCreditors: "Originating Creditors",
	}
	const textMaxLength = 350

	const handleInput = (name) => ({target: {value}}) => {
		setForm({...form, [name]: value})
	}

	const handleChecklist = (name, value) => () => {
		const a = form[name].slice(0)
		a.includes(value) ? a.splice(a.indexOf(value), 1) : a.push(value)
		setForm({...form, [name]: a})
	}

	const submit = (e) => {
		e.preventDefault()
		api.smsTemplate
			.create(form)
			.then(() => navigate("/templates"))
			.then(() => notification.success("Template created"))
			.catch((error) => notification.error(error.Message))
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={2}>
				<Card>
					<List>
						<ListSubheader>Preview Options</ListSubheader>
						{Object.keys(previewOptions).map((k) => (
							<ListItem key={k} onClick={handleChecklist("PreviewOptions", k)} button>
								<Checkbox
									value={k}
									checked={form.PreviewOptions.includes(k)}
									edge="start"
									tabIndex={-1}
								/>
								<ListItemText primary={previewOptions[k]} />
							</ListItem>
						))}
					</List>
				</Card>
				<SettingsCard>
					<List>
						<ListSubheader>Settings</ListSubheader>
						<ListItem dense={false} button component={Link} to={`duplicate`}>
							Duplicate
						</ListItem>
						<ListItem dense={false} button onClick={handleToggle}>
							{archived ? "Restore" : "Archive"}
						</ListItem>
					</List>
				</SettingsCard>
			</Grid>
			<Grid item xs={5}>
				<Card>
					<form onSubmit={submit}>
						<CardHeader title={title} />
						<CardContentFormControls>
							<FormControl required fullWidth={true}>
								<InputLabel variant="outlined">Layout</InputLabel>
								<Select
									label="Layout"
									value={form.LayoutID}
									onChange={handleLayoutOnChange}
									variant="outlined"
									disabled={disabled}
									native
								>
									<option value="" />
									{layouts.map(({LayoutName, LayoutID}) => (
										<option key={LayoutName} value={LayoutID}>
											{LayoutName}
										</option>
									))}
								</Select>
							</FormControl>
							<Grid container justifyContent="space-between" alignItems="center">
								<Grid item xs={true}>
									<FormControl required fullWidth={true}>
										<InputLabel variant="outlined">Locale</InputLabel>
										<Select
											label="Locale"
											value={form.Locale}
											onChange={handleLocaleOnChange}
											variant="outlined"
											disabled={disabled}
											native
										>
											<option value="" disabled />
											{currentLayout.Locales.map((Locale) => (
												<option key={Locale} value={Locale}>
													{Locale}
												</option>
											))}
										</Select>
									</FormControl>
								</Grid>
								<ForValidationPeriod item>
									<FormControl>
										<FormLabel component="label" required>
											For validation period
										</FormLabel>
										<RadioGroup
											row
											name="forValidationPeriod"
											variant="outlined"
											value={form.ForValidationPeriod}
											onChange={handleInput("ForValidationPeriod")}
											disabled={disabled}
										>
											{validationPeriodOptions.map((option) => (
												<FormControlLabel
													key={option}
													value={option}
													control={<Radio required />}
													label={option}
													disabled={disabled}
												/>
											))}
										</RadioGroup>
									</FormControl>
								</ForValidationPeriod>
							</Grid>

							<FormControl fullWidth={true}>
								<TextField
									label="Text"
									value={form.Text}
									onChange={handleInput("Text")}
									inputProps={{maxLength: textMaxLength}}
									variant="outlined"
									disabled={disabled}
									rows="4"
									multiline
									required
								/>
								<Box display="flex" justifyContent="flex-end">
									<FormHelperText variant="outlined">
										{form.Text.length} / {textMaxLength}
									</FormHelperText>
								</Box>
							</FormControl>

							<Autocomplete
								multiple
								options={tags}
								value={form.Tags || []}
								onChange={(_event, value) => setForm({...form, Tags: value})}
								disabled={disabled}
								filterSelectedOptions
								renderInput={(params) => <TextField {...params} label="Tags" placeholder="Tags" />}
							/>
						</CardContentFormControls>
						<CardActions>
							<Box display="flex" justifyContent="flex-end" width="100%">
								<Button type="submit" disabled={disabled}>
									Save
								</Button>
							</Box>
						</CardActions>
					</form>
				</Card>
			</Grid>
			<PreviewGrid item xs={5}>
				<PreviewCard>
					<CardHeader title="Preview" />
					<PreviewCardContent>
						<Preview form={form} />
					</PreviewCardContent>
				</PreviewCard>
			</PreviewGrid>
		</Grid>
	)
}

function Preview({form}) {
	const debouncedForm = useDebounce(form)

	const previewURL = useComputedState(() => {
		return api.smsTemplate.previewUrl(debouncedForm)
	}, [debouncedForm])

	if (!debouncedForm.Locale) {
		return <p>Select locale to start the preview</p>
	}

	return <PreviewIFrame src={previewURL} />
}

const PreviewGrid = styled(Grid)`
	display: flex;
	flex: 1;
`

const PreviewCard = styled(Card)`
	display: flex;
	flex: 1;
	flex-direction: column;
`

const SettingsCard = styled(Card)`
	margin-top: ${theme.spacing(3)};
`

const PreviewCardContent = styled(CardContent)`
	flex: 1;
`

const PreviewIFrame = styled(IFrame)`
	border: 0;
	width: 100%;
	height: 100%;
`

const CardContentFormControls = styled(CardContent)`
	& > .MuiFormControl-root:not(:last-child),
	.MuiGrid-container:not(:last-child) {
		margin-bottom: ${theme.spacing(5)};
	}
`

const ForValidationPeriod = styled(Grid)`
	padding-left: ${theme.spacing(5)};
`

export {Form}
